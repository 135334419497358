import React from "react"
import ArticleCard from "../components/ArticleCard"
import NewHeader from "../components/docheader"
import NewFooter from "../components/docfooter"
import "../assets/styles/page/docArticle.scss"

const article = () => {
  const articleArray = [
    {
      id: "1",
      articleImage: "https://source.unsplash.com/600x600/?sig=59",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work.",
      meta: "news"
    },
    {
      id: "2",
      articleImage: "https://source.unsplash.com/600x600/?sig=31",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work.",
      meta: "news"
    },
    {
      id: "3",
      articleImage: "https://source.unsplash.com/600x600/?sig=45",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work.",
      meta: "news"
    },
    {
      id: "4",
      articleImage: "https://source.unsplash.com/600x600/?sig=90",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work.",
      meta: "news"
    },
    {
      id: "1",
      articleImage: "https://source.unsplash.com/600x600/?sig=90",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work."
    },
    {
      id: "1",
      articleImage: "https://source.unsplash.com/600x600/?sig=31",
      articleHeadtext: "A quick update",
      articleSubtext: "we're taking a break",
      meta: "news"
    },
    {
      id: "1",
      articleImage: "https://source.unsplash.com/600x600/?sig=90",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work.",
      meta: "news"
    },
    {
      id: "1",
      articleImage: "https://source.unsplash.com/600x600/?sig=32",
      articleHeadtext: "A quick update",
      articleSubtext: "we're taking a break",
      meta: "news"
    },
    {
      id: "1",
      articleImage: "https://source.unsplash.com/600x600/?sig=40",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work.",
      meta: "news"
    },
    {
      id: "1",
      articleImage: "https://source.unsplash.com/600x600/?sig=40",
      articleHeadtext: "Learning Lessons",
      articleSubtext: "How to build a design education program for creative work.",
      meta: "news"
    }
  ]

  return (
    <>
      <nav className="header-doc-wrapper">
        <NewHeader />
      </nav>
      <div className="articles--page--cointainer">
        <div className="article--header">
          <h1 className="articles--title">Articles</h1>
          <p className="articles--title--subtitles">
            Explore our community features, case studies, opinion pieces, and more
          </p>
        </div>
        <div className="article--list">
          {articleArray.map(articles => {
            const { articleImage, articleHeadtext, id, articleSubtext, meta } = articles
            return (
              <ArticleCard
                key={id}
                articleImage={articleImage}
                articleHeadtext={articleHeadtext}
                articleSubtext={articleSubtext}
                meta={meta}
              />
            )
          })}
        </div>
        <NewFooter />
      </div>
    </>
  )
}
export default article
